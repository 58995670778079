import { serviceSlice } from "../../app/serviceSlice";
import { IUser } from "./login/loginSlice";
import { authService } from "./nebula.port";

const extendedApiSlice = serviceSlice
    .enhanceEndpoints({ addTagTypes: ["USER_DATA", "TRIALS", "PATIENTS", "UPLOAD_CONFIG", "TRIAL_VISITS"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUserData: builder.query<IUser, void>({
                query: () => authService.getUserData,
                providesTags: ["USER_DATA"],
            }),
            recoverPassword: builder.mutation<void, string>({
                query: (email) => () => authService.recoverPassword(email),
            }),
            validateToken: builder.mutation<void, string>({
                query: (token) => () => authService.validateToken(token),
            }),
            updateCurrentGroup: builder.mutation<any, any>({
                query: (derivedFiles) => () => authService.updateCurrentGroup(derivedFiles),
                invalidatesTags: ["TRIALS", "PATIENTS", "USER_DATA", "UPLOAD_CONFIG", "TRIAL_VISITS"],
            }),
            resetPassword: builder.mutation<void, { token: string; newPassword: string }>({
                query:
                    ({ token, newPassword }) =>
                    () =>
                        authService.resetPassword(token, newPassword),
            }),
        }),
    });

export const {
    useGetUserDataQuery,
    useUpdateCurrentGroupMutation,
    useValidateTokenMutation,
    useResetPasswordMutation,
    useRecoverPasswordMutation,
} = extendedApiSlice;
