import React, { useMemo } from "react";
import { IMedia } from "../studiesSlice";
import { prettyDateWithTime } from "../../../utils/Dates";
import { Box } from "@mui/material";
import DisplayText from "../../../components/DisplayText/DisplayText";

const InfoBox: React.FC<{ media: IMedia }> = ({ media }) => {
    const parsePersonName = (s: string): [string, string] => {
        let last = "";
        let first = "";
        let sq: string[];

        if (s.includes("^")) {
            sq = s.split("^");
        } else if (s.includes(",")) {
            sq = s.split(",");
        } else {
            sq = [s, ""];
        }

        if (sq.length > 1) {
            last = sq[0];
            first = sq.slice(1).join(" ");
        } else {
            last = sq[0];
        }

        return [last.trim(), first.trim()];
    };

    const parseDateOfBirth = (rawDateOfBirth: string) => {
        const year = rawDateOfBirth.substring(0, 4);
        const month = rawDateOfBirth.substring(4, 6);
        const day = rawDateOfBirth.substring(6, 8);

        return `${year}-${month}-${day}`;
    };

    const [parsedLastName, parsedFirstName] = useMemo(() => {
        if (media.dicomData.patientName === undefined) {
            return ["", ""];
        }

        return parsePersonName(media.dicomData.patientName);
    }, [media.dicomData]);

    const parsedDate = useMemo(() => {
        if (media.updatedOn === undefined) {
            return "-";
        }

        const stringDate = new Date(media.updatedOn).toString();

        return prettyDateWithTime(stringDate);
    }, [media.updatedOn]);

    return (
        <Box sx={{ p: 8, display: "flex", flexDirection: "column", gap: "4px" }}>
            <DisplayText type="bodyXSmall" text={`File name: ${media.fileName || "-"}`} />
            {media.dicomData.deviceSerialNumber && (
                <DisplayText type="bodyXSmall" text={`Device serial number: ${media.dicomData.deviceSerialNumber}`} />
            )}
            {media.heidelbergSeriesId && (
                <DisplayText type="bodyXSmall" text={`Series ID: ${media.heidelbergSeriesId || "-"}`} />
            )}
            <DisplayText type="bodyXSmall" text={`Height: ${media.dicomData.bscanHeightSizeInMicrons || 0}μm`} />
            <DisplayText type="bodyXSmall" text={`Width: ${media.dicomData.bscanWidthSizeInMicrons || 0}μm`} />
            {media.dicomData.patientBirthDate && (
                <DisplayText
                    type="bodyXSmall"
                    text={`Date of birth: ${parseDateOfBirth(media.dicomData.patientBirthDate) || "-"}`}
                />
            )}
            {media.dicomData.patientID && (
                <DisplayText type="bodyXSmall" text={`Patient first name: ${parsedFirstName || "-"}`} />
            )}
            {media.dicomData.patientName && (
                <DisplayText type="bodyXSmall" text={`Patient last name: ${parsedLastName || "-"}`} />
            )}
            <DisplayText type="bodyXSmall" text={`Upload: ${parsedDate}`} />
        </Box>
    );
};

export default InfoBox;
