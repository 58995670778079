import React, { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentPatient, Tag } from "../patients/patientsSlice";
import { Box } from "@mui/system";
import { Chip, CircularProgress, FormControl, MenuItem, Select, Switch } from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { AssignmentInd as AssignmentIndIcon } from "@mui/icons-material";
import {
    useAddTagMutation,
    useEditPatientMutation,
    useGetTagsQuery,
    useRemoveTagMutation,
} from "../patients/dataAccess";
import { useSnackbar } from "notistack";
import usePermissions from "../permissions/usePermissions";
import { shortPrettyDate } from "../../utils/Dates";
import { getSelectedTrial } from "../auth/login/loginSlice";
import { getCommaSeparatedPatientName } from "../patients/utils";
import EditablePatientName from "./EditablePatientName";

interface IPatientInformationProps {
    label: string;
    value: string;
}
const PatientInformation: React.FC<IPatientInformationProps> = ({ label, value }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 4 }}>
                <DisplayText type="bodyMedium" variant="regular" text={`${label}:`} />
            </Box>
            <DisplayText type="bodyMedium" variant="semiBold" text={value} />
        </Box>
    );
};

function PatientInfo() {
    const currentPatient = useAppSelector(selectCurrentPatient);
    const selectedTrial = useAppSelector(getSelectedTrial);
    const [editPatient] = useEditPatientMutation();
    const { hasPermissions } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [addTag, { isLoading: isAddingTag }] = useAddTagMutation();
    const [removeTag, { isLoading: isRemovingTag }] = useRemoveTagMutation();

    const [menuOpen, setMenuOpen] = useState(false);

    const { data: tagOptions } = useGetTagsQuery(selectedTrial?.uuid || "", {
        refetchOnMountOrArgChange: true,
        skip: !selectedTrial || !selectedTrial.showTags,
    });

    const handleOpen = () => {
        setMenuOpen(true);
    };

    const handleToggleQC = async () => {
        try {
            await editPatient({
                isQc: !currentPatient.isQc,
                uuid: currentPatient.uuid,
            }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    const handleTagDelete = async (tag: Tag, event: React.MouseEvent) => {
        event.stopPropagation();
        try {
            await removeTag({ patientId: currentPatient.uuid, tagId: tag.uuid }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    const handleTagAdd = async (event: any, obj: any) => {
        setMenuOpen(false);
        const selectedTag = tagOptions!.find((tag) => tag.label === obj.props.value) as Tag;
        try {
            await addTag({ patientId: currentPatient.uuid, tag: selectedTag }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                    xs: "column",
                    md: "row",
                },
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex" }}>
                    <AssignmentIndIcon fontSize="large" />
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <DisplayText type="bodyXSmall" variant="regular" text={"Subject ID"} />
                        <DisplayText type="bodyMedium" variant="semiBold" text={currentPatient.patientId} />
                    </Box>
                </Box>
                <EditablePatientName patient={currentPatient} />
                {selectedTrial?.showTags && (
                    <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 440 }} size="small">
                        <Box mb={5} mt={5}>
                            <DisplayText text="Tags" type="bodyMedium" variant="regular" />
                        </Box>
                        {tagOptions ? (
                            <Select
                                disabled={isAddingTag || isRemovingTag}
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                placeholder="Add tags"
                                MenuProps={{
                                    open: menuOpen,
                                    onClose: () => setMenuOpen(false),
                                }}
                                onOpen={handleOpen}
                                displayEmpty
                                value={currentPatient.tags}
                                onChange={(event, obj) => handleTagAdd(event, obj)}
                                renderValue={(selected) => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                        {selected.length
                                            ? selected.map((tag: any) => (
                                                  <Chip
                                                      size="small"
                                                      key={tag.uuid}
                                                      label={tag.label}
                                                      style={{ backgroundColor: tag.color }}
                                                      onMouseDown={(event) => event.stopPropagation()}
                                                      onDelete={(e) => handleTagDelete(tag, e)}
                                                  />
                                              ))
                                            : "Add a new tag"}
                                    </Box>
                                )}
                            >
                                {tagOptions.filter(
                                    (option) => !currentPatient.tags.some((tag: Tag) => tag.uuid === option.uuid)
                                ).length === 0 && (
                                    <MenuItem disabled>
                                        <em>No tags</em>
                                    </MenuItem>
                                )}
                                {tagOptions
                                    .filter(
                                        (option) => !currentPatient.tags.some((tag: Tag) => tag.uuid === option.uuid)
                                    )
                                    .map((tag) => (
                                        <MenuItem key={tag.uuid} value={tag.label} component="li">
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                                <span
                                                    style={{
                                                        marginRight: 5,
                                                        display: "inline-block",
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: "50%",
                                                        backgroundColor: tag.color,
                                                    }}
                                                ></span>
                                                {tag.label}
                                            </Box>
                                        </MenuItem>
                                    ))}
                            </Select>
                        ) : (
                            <CircularProgress /> // Replace Spinner with the actual spinner component you are using
                        )}
                    </FormControl>
                )}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                }}
            >
                <PatientInformation label="Group/Site" value={currentPatient.site} />
                <PatientInformation label="Date of birth" value={shortPrettyDate(currentPatient.dateOfBirth)} />
                <PatientInformation label="Gender" value={currentPatient.gender} />
                {hasPermissions(["QC"]) ? (
                    <Box style={{ display: "flex", alignItems: "center", height: 28 }}>
                        <DisplayText type="bodyMedium" variant="regular" text="QC only:" />
                        <Switch color="primary" onChange={handleToggleQC} checked={currentPatient.isQc} />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}

export default React.memo(PatientInfo);
