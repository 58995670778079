import {
    Card,
    CardActionArea,
    CardMedia,
    Checkbox,
    CardProps,
    Button,
    Skeleton,
    Popover,
    Chip,
    CircularProgress,
    MenuItem,
    Switch,
} from "@mui/material";
import {
    Info as InfoIcon,
    Launch as LaunchIcon,
    Beenhere as BeenhereIcon,
    Visibility as VisibilityIcon,
    ContentCopy as ContentCopyIcon,
    DeleteOutline as DeleteOutlineIcon,
    Download as DownloadIcon,
    StickyNote2 as NoteIcon,
    ModeEdit as ModeEditIcon,
    HideImage as HideImageIcon,
    Image as ImageIcon,
} from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { selectOrionSessionId } from "../../orion/orionSlice";
import { useLaunchOrionMutation, useCreateOrionSessionMutation } from "../../orion/dataAccess";
import { useHistory } from "react-router-dom";
import { figureFlavor, figureCardTitle, getMediaLabel, mimeTypeToExtension } from "../../../utils/MediaUtils";
import { Box, styled } from "@mui/system";
import { Analysis, IEncounter, IMedia } from "../studiesSlice";
import {
    useDeleteStudyMutation,
    useDownloadStudyMutation,
    useGetAnalysisInfoQuery,
    useAddStudyCommentMutation,
    useEditMediaVisibilityMutation,
} from "../dataAccess";
import { common, grey } from "@mui/material/colors";
import React, { useCallback, useMemo, useState } from "react";
import DisplayText from "../../../components/DisplayText/DisplayText";
import SizedContainerPDF from "../../../components/PDF/SizedConteiner";
import LateralityPill from "./LateralityPill";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentPatient } from "../../patients/patientsSlice";
import queryString from "query-string";
import { getQueryParamFromStudyId } from "../../media/utils";
import MenuList from "../../../components/MenuList/MenuList";
import CopyCaseInfo from "../../media/CopyCaseInfo";
import usePermissions from "../../permissions/usePermissions";
import { useConfirmationModal } from "../../../components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import config from "../../../config.json";
import TextFromFile from "../../../components/TextFromFile/TextFromFile";
import CSVToTable from "../../../components/CSVToTable/CSVToTable";
import ReportEligibility from "../../media/ReportEligibility";
import ToolTip from "../../../components/Tooltip/Tooltip";
import Divider from "@mui/material/Divider";
import { longPrettyDate, prettyDateWithTime } from "../../../utils/Dates";
import EditModality from "../../media/EditModality";
import useRoles from "../../permissions/useRoles";
import StudyCommentsDialog from "../StudyCommentsDialog";
import InfoBox from "./InfoBox";
import FormSelectorModal from "../../customForm/FormSelectorModal";
import FormTemplateModal from "../../customForm/FormTemplateModal";
import { FormTemplateDefinition } from "../../customForm/nebula.port";
import FormViewOnlyModal from "../../customForm/FormViewOnlyModal";

const CustomCard = styled(Card)<CardProps>(({}) => ({
    backgroundColor: "2B323E",
    width: "100%",
}));

interface MediaProps {
    media: IMedia;
    visit: IEncounter;
    onCheckboxSelect: (id: string[], isChecked: boolean) => void;
    selected: boolean;
    actionsDisable: boolean;
    token?: string;
    isLoading: boolean;
    error: boolean;
}

const OpenInOrionButton = styled(Button)(({}) => ({
    "color": common.white,
    "border": `1px solid ${common.white}`,
    "&:hover": {
        borderColor: grey[300],
    },
}));

interface OpenInOrionMultipleProps {
    mediaUuid: string;
    onLaunchOrion: (attachmentIds: string[]) => void;
}

const AttachmentsSelector = (props: {
    onClick: () => void;
    mediaUuid: string;
    renderAttachment: (attachment: any) => JSX.Element;
}) => {
    const currentPatient = useAppSelector(selectCurrentPatient);
    const { data: attachments, error } = useGetAnalysisInfoQuery({
        patientId: currentPatient.uuid,
        mediaId: props.mediaUuid,
    });

    if (error) {
        return (
            <Box sx={{ p: 16, minWidth: 311, display: "flex", justifyContent: "center" }}>
                Error loading attachments
            </Box>
        );
    }

    if (!attachments) {
        return (
            <Box sx={{ p: 16, minWidth: 311, display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 16, minWidth: 300 }}>
            <Box
                onClick={props.onClick}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}
            >
                <DisplayText type="bodyLarge" text="Open a user file" />
                <DisplayText
                    type="bodySmall"
                    variant="semiBold"
                    text="+New"
                    style={{ textDecoration: "underline", cursor: "pointer", marginBottom: 12 }}
                />
            </Box>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 10, mt: 8 }}>
                {attachments.length > 0 ? (
                    attachments.map(props.renderAttachment)
                ) : (
                    <DisplayText
                        type="bodySmall"
                        variant="semiBold"
                        text="No files found"
                        style={{ textAlign: "center" }}
                    />
                )}
            </Box>
        </Box>
    );
};

const OpenInOrionMultiple: React.FC<OpenInOrionMultipleProps> = ({ mediaUuid, onLaunchOrion }) => {
    const { userHasRole } = useRoles();
    const showUserEmail = !userHasRole("SPONSOR");
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLaunchOrion = (selectedAttachments: string[]) => {
        onLaunchOrion(selectedAttachments);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const renderAttachment = (attachment: Analysis) => {
        return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "space-between" }}>
                <Box
                    sx={{ display: "flex", gap: 8, alignItems: "center" }}
                    onClick={() => handleLaunchOrion([attachment.uuid])}
                >
                    <DisplayText
                        type="bodySmall"
                        text={showUserEmail ? attachment.updatedBy.email : "See results from: "}
                        variant="semiBold"
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    />
                </Box>
                <DisplayText
                    style={{ fontStyle: "italic" }}
                    type="bodyXSmall"
                    text={longPrettyDate(new Date(attachment.updatedOn))}
                />
            </Box>
        );
    };

    return (
        <>
            <OpenInOrionButton
                fullWidth
                disabled={false}
                size="small"
                variant="outlined"
                startIcon={<LaunchIcon />}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClick}
            >
                Orion
            </OpenInOrionButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <AttachmentsSelector
                    onClick={() => handleLaunchOrion([""])}
                    mediaUuid={mediaUuid}
                    renderAttachment={renderAttachment}
                />
            </Popover>
        </>
    );
};

const ViewButton = styled(Button)(({ theme }) => ({
    "color": theme.palette.getContrastText(common.white),
    "backgroundColor": common.white,
    // "border": `1px solid ${common.white}`,
    "&:hover": {
        backgroundColor: grey[300],
    },
}));

const StudyCard: React.FC<MediaProps> = ({
    media,
    onCheckboxSelect,
    visit,
    selected,
    actionsDisable,
    isLoading,
    error,
}) => {
    const currentPatient = useAppSelector(selectCurrentPatient);
    const [copyQueryInfoOpen, setCopyQueryInfoOpen] = useState<boolean>(false);
    const [reportEligibilityOpen, setReportEligibilityOpen] = useState<boolean>(false);
    const [editModalityOpen, setEditModalityOpen] = useState<boolean>(false);
    const cardTitle = figureCardTitle(media);
    const { hasPermissions } = usePermissions();
    const flavor = figureFlavor(media);
    const { userHasRole } = useRoles();

    const orionSessionId = useAppSelector(selectOrionSessionId);
    const history = useHistory();
    const [deleteStudy] = useDeleteStudyMutation();
    const { openConfirmationModal } = useConfirmationModal({
        title: "Are you sure you want to remove the study?",
        content: "You will no longer be able to access the study.",
        acceptLabel: "Confirm",
    });

    const [launchOrion] = useLaunchOrionMutation();
    const [createOrionSession] = useCreateOrionSessionMutation();
    const [downloadStudy] = useDownloadStudyMutation();
    const [addStudyComment] = useAddStudyCommentMutation();
    const [editMediaVisibility] = useEditMediaVisibilityMutation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const [noteDialogOpen, setNoteDialogOpen] = useState(false);

    const handleOpenNoteDialog = () => setNoteDialogOpen(true);

    const handleCloseNoteDialog = () => setNoteDialogOpen(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const [formSelectorOpen, setFormSelectorOpen] = useState<boolean>(false);
    const [formTemplateOpen, setFormTemplateOpen] = useState<boolean>(false);
    const [formViewOnly, setFormViewOnly] = useState<boolean>(false);
    const [selectedForm, setSelectedForm] = useState<string>("");

    const handleOpenFormSelector = () => setFormSelectorOpen(true);
    const handleCloseFormSelector = () => setFormSelectorOpen(false);

    const handleSelectForm = (formId: string, hasFormBeenSubmittedByUser: boolean) => {
        setSelectedForm(formId);

        if (hasFormBeenSubmittedByUser) {
            setFormViewOnly(true);
        } else {
            setFormTemplateOpen(true);
        }
    };

    const handleCloseFormTemplate = () => {
        setFormTemplateOpen(false);
    };

    const handleCloseFormViewOnly = () => {
        setFormViewOnly(false);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const onViewerClick = () => {
        // Adding random id before id as query params to be able to differentiate when having to media cards
        // with the same id. This is important for example when closing a card
        history.push({
            pathname: `/visits/${currentPatient!.uuid}`,
            search: queryString.stringify({ st: getQueryParamFromStudyId(media.uuid) }),
        });
    };

    const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;

        onCheckboxSelect([media.uuid], isChecked);
    };

    const getStudyUrL = (media: IMedia): string => {
        const fileExtension = media.mediaUrl.split(".").pop();

        const flavor = figureFlavor(media);

        const kind = flavor === "oct-mp4" || flavor === "oct-photo" ? "oct-image" : fileExtension?.toLowerCase();

        return `/media/stream/${kind}/${media.uuid}?res=full`;
    };

    const handleDownloadStudy = async () => {
        enqueueSnackbar("Download is being prepared...", {
            variant: "info",
            persist: true,
        });

        try {
            const studyUrl = getStudyUrL(media);

            const { fileBlob, contentType } = await downloadStudy(studyUrl).unwrap();

            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([fileBlob]));
            // Get file extension
            const fileExtension = mimeTypeToExtension[contentType];

            // Crete link element
            const link = document.createElement("a");
            link.href = url;

            const fileNameWithExtension = `${media.fileName.substring(
                0,
                media.fileName.lastIndexOf(".")
            )}.${fileExtension}`;
            link.setAttribute("download", fileNameWithExtension);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link!.parentNode!.removeChild(link);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("There was a problem with the download.", {
                variant: "error",
            });
        }
    };

    const handleDeleteStudy = () => {
        return openConfirmationModal(async () => {
            try {
                await deleteStudy({ studyId: media.uuid, patientId: currentPatient!.uuid }).unwrap();
            } catch (e) {
                enqueueSnackbar("Something went wrong.", { variant: "error" });
            }
        });
    };

    const handleOpenReportEligibility = () => {
        setReportEligibilityOpen(true);
    };

    const handleOpenEditModality = () => {
        setEditModalityOpen(true);
    };

    const handleCloseEditModality = () => {
        setEditModalityOpen(false);
    };

    const handleCloseReportEligibility = () => {
        setReportEligibilityOpen(false);
    };

    const handleOpenCopyCaseInfo = () => {
        setCopyQueryInfoOpen(true);
    };

    const handleCloseCopyCaseInfo = () => {
        setCopyQueryInfoOpen(false);
    };

    const handleAddComment = async (newNote: string) => {
        await addStudyComment({
            mediaId: media.uuid,
            note: newNote,
            patientId: currentPatient!.uuid,
        }).unwrap();
        return;
    };

    const handleLaunchOrion = async (attachmentIds: string[]) => {
        try {
            enqueueSnackbar("Opening file in Orion...", {
                variant: "info",
                persist: true,
            });

            const orionSession = await createOrionSession(orionSessionId).unwrap();

            setTimeout(() => {
                launchOrion({ sessionId: orionSession.id, studiesIds: [media.uuid], attachmentIds: attachmentIds });
                closeSnackbar();
            }, 10000);

            const baseUrl = process.env.REACT_APP_NEBULA_SERVER_V1_URL || config.api_baseurl.orion;
            const host = baseUrl.replace("/api/v1", "");
            const params = `${host}?id=${orionSession.id}`;
            const encoded = encodeURIComponent(params);
            // @ts-ignore
            const url = `vxorion://${encoded}`;
            // @ts-ignore
            window.location.assign(url);
        } catch (e) {
            closeSnackbar();

            enqueueSnackbar("Something wrong happened", {
                variant: "error",
            });
        }
    };

    const handleEditVisibility = async () => {
        try {
            await editMediaVisibility({
                patientId: currentPatient.uuid,
                mediaId: media.uuid,
                visible: !media.visible,
            }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    const cardHeight = "200px";
    const imageElement = useCallback(() => {
        if (media) {
            // No media url, probably media has not yet been processed
            if (!media.mediaUrl) {
                return (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <DisplayText type="bodyMedium" text="Media not available" />
                    </Box>
                );
            }

            switch (flavor) {
                case "attachment":
                    return (
                        <Box sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                            <AttachFileIcon height={64} width={64} />
                            <DisplayText type="bodyMedium" text={media.fileName} />
                        </Box>
                    );
                case "csv":
                    return <CSVToTable style={{ borderRight: 0, borderLeft: 0 }} url={media.mediaUrl} />;
                case "mp4":
                    return (
                        <Box>
                            <CardMedia
                                component="video"
                                src={media.mediaUrl}
                                controls={true}
                                sx={{
                                    height: cardHeight,
                                    objectFit: "fill",
                                }}
                            />
                        </Box>
                    );
                case "oct-mp4":
                    return (
                        <Box>
                            <Box
                                sx={{
                                    zIndex: 999,
                                    position: "absolute",
                                    top: 6,
                                    left: 6,
                                    padding: 5,
                                    backgroundColor: "#0000003b",
                                    borderRadius: 2,
                                }}
                            >
                                <DisplayText
                                    type="bodyXSmall"
                                    text={`Quality: ${media.dicomData?.imageQuality?.toFixed(3) || "-"}`}
                                />
                            </Box>
                            <Box
                                sx={{
                                    zIndex: 999,
                                    position: "absolute",
                                    top: 6,
                                    right: 6,
                                    padding: 5,
                                    backgroundColor: "#0000003b",
                                    borderRadius: 2,
                                }}
                            >
                                <DisplayText type="bodyXSmall" text={`${media.dicomData.numberOfFrames} Slices`} />
                            </Box>
                            <CardMedia
                                component="video"
                                src={media.mediaUrl}
                                controls={true}
                                sx={{
                                    height: cardHeight,
                                    objectFit: "fill",
                                }}
                            />
                        </Box>
                    );
                case "photo":
                case "oct-photo":
                    return (
                        <CardMedia
                            component="img"
                            onContextMenu={(e: React.MouseEvent<HTMLDivElement>) => {
                                e.preventDefault();
                            }}
                            alt={media.fileName}
                            image={media.mediaUrl}
                            sx={{ height: cardHeight, pointerEvents: "none" }}
                        />
                    );
                case "pdf":
                    return (
                        <Box sx={{ height: "100%" }}>
                            <SizedContainerPDF
                                fileName={
                                    media.fileName ||
                                    `${media.dicomData.Laterality ? `${media.dicomData.Laterality} - ` : ""}${
                                        media.dicomData.ScanDateAndTime
                                    }` ||
                                    "PDF File"
                                }
                                pdfUrl={media.mediaUrl}
                            />
                        </Box>
                    );

                case "text":
                    return (
                        <Box
                            sx={{
                                border: "1px solid rgba(81, 81, 81, 1)",
                                borderLeft: 0,
                                borderRight: 0,
                                height: "100%",
                                overflow: "auto",
                            }}
                        >
                            <Box sx={{ px: 14 }}>
                                <TextFromFile url={media.mediaUrl} />
                            </Box>
                        </Box>
                    );
                default:
                    return null;
            }
        }
    }, []);

    const menuListOptions = [
        ...(hasPermissions(["QC"])
            ? [{ label: "Query: Copy Case Info", Icon: <ContentCopyIcon />, onClick: handleOpenCopyCaseInfo }]
            : []),
        ...(hasPermissions(["QC"])
            ? [{ label: "Report Eligibility", Icon: <ContentCopyIcon />, onClick: handleOpenReportEligibility }]
            : []),
        ...(!userHasRole("SPONSOR") && !userHasRole("SITE_USER")
            ? [{ label: "Edit Modality", Icon: <ModeEditIcon />, onClick: handleOpenEditModality }]
            : []),
        ...(userHasRole("PROJECT_MANAGER") || userHasRole("MASTER_ADMIN")
            ? [
                  {
                      label: media.visible ? "Hide" : "Show",
                      Icon: media.visible ? <HideImageIcon /> : <ImageIcon />,
                      onClick: handleEditVisibility,
                  },
              ]
            : []),
        ...(hasPermissions(["Download"])
            ? [
                  {
                      label: "Download",
                      Icon: <DownloadIcon />,
                      onClick: handleDownloadStudy,
                  },
              ]
            : []),
        { label: "Custom Forms", Icon: <DescriptionIcon />, onClick: handleOpenFormSelector },
        ...(hasPermissions(["Delete"])
            ? [
                  {
                      label: "Remove",
                      Icon: <DeleteOutlineIcon color="error" />,
                      labelStyle: { color: "#f44336" },
                      onClick: handleDeleteStudy,
                  },
              ]
            : []),
    ];

    // i.e. if there are any IXD's associated with it
    const hasMediaBeenReadInOrion = useMemo(() => {
        return media.hasBeenRead;
    }, [media]);

    if (!cardTitle || cardTitle === "hide") return null;

    if (error) {
        return <Box>En error occurred. Please try again.</Box>;
    }

    if (isLoading) {
        return (
            <CustomCard>
                <Box sx={{ py: 6, px: 9, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box>
                            <Skeleton variant="text" width={70} />
                            <Skeleton variant="text" width={120} height={20} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flex: 1,
                        }}
                    >
                        <Box sx={{ px: 9 }}>
                            <Skeleton variant="text" width={40} height={40} />
                        </Box>
                        <Box sx={{ display: "flex", gap: 10 }}>
                            <Skeleton variant="circular" width={16} height={16} />
                            <Skeleton variant="circular" width={16} height={16} />
                        </Box>
                    </Box>
                </Box>
                <CardActionArea>
                    <Skeleton sx={{ height: 200 }} animation="wave" variant="rectangular" />
                </CardActionArea>
                <Box sx={{ py: 6, px: 9 }}>
                    <Box sx={{ mb: 6 }}>
                        <Skeleton variant="text" width={120} />
                    </Box>
                    <Box sx={{ display: "flex", gap: 6 }}>
                        <Skeleton variant="text" width="100%" height={43} />
                        <Skeleton variant="text" width="100%" height={43} />
                    </Box>
                </Box>
            </CustomCard>
        );
    }

    const showReadIcon = hasMediaBeenReadInOrion && hasPermissions(["Launch Orion"]);

    const NotificationComponent = media.comments?.length ? (
        <ToolTip content={<DisplayText type="bodyXSmall" text={`Last: ${media.comments!.at(-1)!.comment}`} />}>
            <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                <Chip
                    icon={<NoteIcon />}
                    onClick={handleOpenNoteDialog}
                    label="Notes"
                    size="small"
                    color={media.comments?.length ? "warning" : "default"}
                />
            </Box>
        </ToolTip>
    ) : (
        <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <Chip
                icon={<NoteIcon />}
                disabled={media.hidden}
                onClick={handleOpenNoteDialog}
                label="Add notes"
                size="small"
                color={media.comments?.length ? "warning" : "default"}
            />
        </Box>
    );

    const renderOrionButton = () => {
        if (!hasPermissions(["Launch Orion"])) return null;

        if (flavor === "oct-mp4" || flavor === "oct-photo" || flavor === "photo") {
            return <OpenInOrionMultiple mediaUuid={media.uuid} onLaunchOrion={handleLaunchOrion} />;
        }

        return null;
    };

    return (
        <>
            <CopyCaseInfo
                siteName={currentPatient.site}
                visitName={visit.visitName}
                visitId={visit.visitId}
                patientLocalId={currentPatient!.patientId}
                patientId={currentPatient!.uuid}
                isOpen={copyQueryInfoOpen}
                study={media}
                onClose={handleCloseCopyCaseInfo}
            />
            <ReportEligibility
                siteName={currentPatient.site}
                visitName={visit.visitName}
                patientId={currentPatient!.patientId}
                isOpen={reportEligibilityOpen}
                study={media}
                onClose={handleCloseReportEligibility}
            />
            <EditModality
                mediaIds={[media.uuid]}
                isOpen={editModalityOpen}
                onClose={handleCloseEditModality}
                currentModality={media.dicomData.performedProcedureStepDescription}
                patientId={currentPatient!.uuid}
            />

            <CustomCard
                sx={{ opacity: `${media.hidden || !media.visible ? 0.5 : 1}` }}
                data-test-id={`studyCard:${media.fileName}`}
            >
                <Box sx={{ py: 6, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox checked={selected} disabled={media.hidden} onChange={handleCheckboxClick} />
                        <Box>
                            <Box
                                style={{ cursor: "pointer" }}
                                onClick={() => onCheckboxSelect([media.uuid], !selected)}
                            >
                                <DisplayText type="bodyMedium" variant="semiBold" text={getMediaLabel(media)} />
                            </Box>
                            <DisplayText type="bodyXSmall" variant="regular" text={media.dicomData.ScanPattern} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flex: 1,
                            flexDirection: "row-reverse",
                            px: 9,
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {showReadIcon && (
                                <ToolTip content={<DisplayText type="bodyXSmall" text="Read" />}>
                                    <Box sx={{ mr: 10, display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                        <BeenhereIcon fontSize="small" sx={{ color: "#3babdc" }} />
                                    </Box>
                                </ToolTip>
                            )}
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}
                                onClick={handlePopoverOpen}
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >
                                <InfoIcon fontSize="small" />
                            </Box>
                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: "none",
                                }}
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <InfoBox media={media} />
                            </Popover>
                            {menuListOptions.length > 0 && !media.hidden ? (
                                <Box sx={{ mr: -9 }}>
                                    <MenuList options={menuListOptions} />
                                </Box>
                            ) : null}
                        </Box>
                        {media.laterality ? (
                            <Box sx={{ pr: 9 }}>
                                <LateralityPill laterality={media.laterality} />
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: "relative",
                        height: 200,
                        overflow: "auto",
                    }}
                >
                    {imageElement()}
                </Box>
                <Box sx={{ py: 6, px: 9 }}>
                    <Box sx={{ mb: 24, display: "flex", justifyContent: "space-between", alignItems: "initial" }}>
                        <DisplayText type="bodyXSmall" text={`Manufacturer: ${media.dicomData.manufacturer || "-"}`} />
                        {hasPermissions(["QC"]) ? NotificationComponent : null}
                    </Box>
                    <Box sx={{ display: "flex", gap: 6 }}>
                        {!media.hidden ? renderOrionButton() : null}
                        <ViewButton
                            fullWidth
                            disabled={actionsDisable || media.hidden}
                            size="small"
                            variant="contained"
                            onClick={onViewerClick}
                            startIcon={<VisibilityIcon />}
                        >
                            view
                        </ViewButton>
                    </Box>
                </Box>
                <StudyCommentsDialog
                    comments={media.comments}
                    isOpen={noteDialogOpen}
                    onAdd={handleAddComment}
                    onClose={handleCloseNoteDialog}
                />
            </CustomCard>
            {formSelectorOpen && (
                <FormSelectorModal
                    open={formSelectorOpen}
                    mediaId={media.uuid}
                    onClose={handleCloseFormSelector}
                    onSelectForm={handleSelectForm}
                />
            )}
            {formTemplateOpen && (
                <FormTemplateModal
                    open={formTemplateOpen}
                    onClose={handleCloseFormTemplate}
                    mediaId={media.uuid}
                    mediaName={media.fileName}
                    formId={selectedForm}
                />
            )}
            {formViewOnly && (
                <FormViewOnlyModal
                    open={formViewOnly}
                    onClose={handleCloseFormViewOnly}
                    mediaId={media.uuid}
                    mediaName={media.fileName}
                    formId={selectedForm}
                />
            )}
        </>
    );
};

export default React.memo(StudyCard);
