import { DataResponse } from "../../shared/DataResponse";

export enum Laterality {
    OS = "OS",
    OD = "OD",
}

export enum OptionType {
    "input" = "input",
    "value" = "value",
}

export interface CrfFieldOptionDefaultType {
    label: string;
    type: OptionType.value;
    value: any;
}

enum Modality {
    "OCT" = "OCT",
    "CFP" = "CFP",
}

enum InputType {
    "string" = "string",
    "number" = "number",
}

interface CrfFieldOptionInputType {
    label: string;
    type: OptionType.input;
    inputType: InputType;
    unit?: string;
}

export enum CrfFieldType {
    "list" = "list",
    "string" = "string",
    "float" = "float",
    "positiveFloat" = "positiveFloat",
    "integer" = "integer",
    "positiveInteger" = "positiveInteger",
    "date" = "date",
}

interface CrfFreeTextField {
    uuid: string;
    fieldName: string;
    description?: string;
    fieldType:
        | CrfFieldType.string
        | CrfFieldType.float
        | CrfFieldType.positiveFloat
        | CrfFieldType.integer
        | CrfFieldType.positiveInteger;
    modalities: Modality[];
}

export interface CrfDateField {
    uuid: string;
    fieldName: string;
    description?: string;
    fieldType: CrfFieldType.date;
    modalities: Modality[];
}

export type FieldOption = CrfFieldOptionDefaultType | CrfFieldOptionInputType;
interface CrfListField {
    uuid: string;
    fieldName: string;
    description?: string;
    fieldType: CrfFieldType.list;
    modalities: Modality[];
    fieldOptions: FieldOption[];
}

export type CrfField = (CrfListField | CrfFreeTextField | CrfDateField) & {
    isRequired: boolean;
    laterality: Laterality;
    precision?: number;
    unit?: string;
};

export type CrfReadingValue = { fieldName: string; fieldValue: any; uuid: string };

export interface CrfReading {
    user: {
        email: string;
        uuid: string;
    };
    laterality: Laterality;
    notes: string;
    values: CrfReadingValue[] | [];
    final: boolean;
    isEditable?: boolean;
    createdOn?: Date;
    updatedOn?: Date;
}

export interface CrfVisit {
    uuid: string;
    visitName: string;
    screening: boolean;
    fields: CrfField[];
    data: CrfReading[];
}

export interface Crf {
    uuid: string;
    trial: {
        name: string;
        uuid: string;
    };
    patientId: string;
    visits: CrfVisit[];
}

export interface ICRFEEditRequest {
    data?: CrfReadingValue[];
    notes?: string;
    visitId: string;
    laterality: Laterality;
}

export interface ICRFFinalRequest {
    visitId: string;
    laterality: Laterality;
    userId: string;
}

export interface CRFService {
    getPatientCrf: (patientId: string) => Promise<DataResponse<Crf>>;
    editCRF: (patientId: string, CRFEdit: ICRFEEditRequest) => Promise<DataResponse<void>>;
    removeCrfValues: (
        patientId: string,
        visitId: string,
        laterality: string,
        fieldsToRemove: string[]
    ) => Promise<DataResponse<void>>;
    setFinal: (patientId: string, finalRequest: ICRFFinalRequest) => Promise<DataResponse<void>>;
    unsetFinal: (patientId: string, finalRequest: ICRFFinalRequest) => Promise<DataResponse<void>>;
}
