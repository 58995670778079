import {
    Box,
    ButtonBase,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableRowProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback } from "react";
import usePermissions from "../permissions/usePermissions";
import { useAppSelector } from "../../app/hooks";
import { IPatient, selectCurrentPatient, selectPatientSort } from "./patientsSlice";
import { getSelectedTrial } from "../auth/login/loginSlice";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { ArrowDropUp } from "@mui/icons-material";
import theme from "../../app/theme";
import { getUniqueObjects } from "../../utils/Array";
import { shortPrettyDate } from "../../utils/Dates";
import AnimatedChips from "../../components/AnimatedChips/AnimatedChips";
import { getCommaSeparatedPatientName } from "./utils";

const PatientCell = styled(TableCell)<{ width?: string }>(({ theme, width }) => ({
    borderBottom: "none",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width,
    padding: theme.spacing(5),
    height: theme.spacing(1),
    fontSize: theme.spacing(12),
}));

const PatientRow = styled(TableRow)<TableRowProps>(() => ({
    "borderBottom": "none",
    "&:hover": {
        cursor: "pointer",
    },
}));

interface PatientTableProps {
    onSort: (event: React.MouseEvent<HTMLElement>, field: keyof IPatient) => void;
    onPatientClick: (event: React.MouseEvent<HTMLElement>, patient: IPatient) => void;
    patients: IPatient[];
    isLoading: boolean;
}

const PatientTable: React.FC<PatientTableProps> = ({ onSort, isLoading, onPatientClick, patients }) => {
    const { hasPermissions } = usePermissions();

    const patientSort = useAppSelector(selectPatientSort);
    const selectedTrial = useAppSelector(getSelectedTrial);
    const currentPatient = useAppSelector(selectCurrentPatient);

    const getPatientVisitDate = useCallback(
        (patientDTO: IPatient) => {
            if (!patients) return "";

            if (patientDTO["earliestISODate"] && patientDTO["latestISODate"]) {
                return `${shortPrettyDate(patientDTO["earliestISODate"])} ~ ${shortPrettyDate(
                    patientDTO["latestISODate"]
                )}`;
            }

            return patientDTO["earliestISODate"];
        },
        [patients]
    );

    const getPatientUniqueStates = useCallback(
        (patient: IPatient) => {
            const stateColor: Record<string, string> = {
                LOW: "info.main",
                MEDIUM: "warning.main",
                HIGH: "error.main",
                HIGHEST: "error.main",
                DONE: "success.main",
                DEFAULT: "info.main",
            };

            return getUniqueObjects(patient["workflowStates"], "value").map((state, i) => ({
                id: state.value,
                label: state.label,
                color: stateColor[state.priority || "DEFAULT"],
            }));
        },
        [patients]
    );

    return (
        <Table size="small" sx={{ tableLayout: "fixed" }}>
            <TableHead>
                <TableRow>
                    {hasPermissions(["QC"]) ? (
                        <PatientCell width="60px" align="left">
                            <ButtonBase onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "isQc")}>
                                Status
                                {patientSort.field === "isQc" ? (
                                    patientSort.order === "desc" ? (
                                        <ArrowDropDown />
                                    ) : (
                                        <ArrowDropUp />
                                    )
                                ) : (
                                    ""
                                )}
                            </ButtonBase>
                        </PatientCell>
                    ) : null}
                    {selectedTrial?.workflowActive && (
                        <PatientCell width="180px" align="left">
                            <ButtonBase
                                onClick={(event: React.MouseEvent<HTMLElement>) =>
                                    // @ts-ignore
                                    handleClick(event, "workflowState.label")
                                }
                            >
                                Workflow State
                                {/* @ts-ignore */}
                                {patientSort.field === "workflowState.label" ? (
                                    patientSort.order === "desc" ? (
                                        <ArrowDropDown />
                                    ) : (
                                        <ArrowDropUp />
                                    )
                                ) : (
                                    ""
                                )}
                            </ButtonBase>
                        </PatientCell>
                    )}
                    <PatientCell align="left" width="160px">
                        <ButtonBase onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "patientId")}>
                            Subject ID
                            {patientSort.field === "patientId" ? (
                                patientSort.order === "desc" ? (
                                    <ArrowDropDown />
                                ) : (
                                    <ArrowDropUp />
                                )
                            ) : (
                                ""
                            )}
                        </ButtonBase>
                    </PatientCell>

                    {!currentPatient && (
                        <PatientCell width="180px" align="left">
                            <ButtonBase onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "lastName")}>
                                Name
                                {patientSort.field === "lastName" ? (
                                    patientSort.order === "desc" ? (
                                        <ArrowDropDown />
                                    ) : (
                                        <ArrowDropUp />
                                    )
                                ) : (
                                    ""
                                )}
                            </ButtonBase>
                        </PatientCell>
                    )}
                    {selectedTrial?.showTags && (
                        <PatientCell>
                            <ButtonBase sx={{ cursor: "default" }}>Tags</ButtonBase>
                        </PatientCell>
                    )}
                    <PatientCell width="120px" align="left">
                        <ButtonBase onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "site")}>
                            Site Name
                            {patientSort.field === "site" ? (
                                patientSort.order === "desc" ? (
                                    <ArrowDropDown />
                                ) : (
                                    <ArrowDropUp />
                                )
                            ) : (
                                ""
                            )}
                        </ButtonBase>
                    </PatientCell>
                    {!currentPatient && (
                        <PatientCell width="60px" align="left">
                            <ButtonBase onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "gender")}>
                                Gender
                                {patientSort.field === "gender" ? (
                                    patientSort.order === "desc" ? (
                                        <ArrowDropDown />
                                    ) : (
                                        <ArrowDropUp />
                                    )
                                ) : (
                                    ""
                                )}
                            </ButtonBase>
                        </PatientCell>
                    )}
                    {!currentPatient && (
                        <PatientCell width="90px" align="left">
                            <ButtonBase
                                onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "dateOfBirth")}
                            >
                                DOB
                                {patientSort.field === "dateOfBirth" ? (
                                    patientSort.order === "desc" ? (
                                        <ArrowDropDown />
                                    ) : (
                                        <ArrowDropUp />
                                    )
                                ) : (
                                    ""
                                )}
                            </ButtonBase>
                        </PatientCell>
                    )}
                    {!currentPatient && (
                        <PatientCell width="60px" align="left">
                            <ButtonBase onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "visits")}>
                                Visit(s)
                                {patientSort.field === "visits" ? (
                                    patientSort.order === "desc" ? (
                                        <ArrowDropDown />
                                    ) : (
                                        <ArrowDropUp />
                                    )
                                ) : (
                                    ""
                                )}
                            </ButtonBase>
                        </PatientCell>
                    )}
                    <PatientCell width="160px" align="left">
                        <ButtonBase onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "latestISODate")}>
                            Visit date(s)
                            {patientSort.field === "latestISODate" ? (
                                patientSort.order === "desc" ? (
                                    <ArrowDropDown />
                                ) : (
                                    <ArrowDropUp />
                                )
                            ) : (
                                ""
                            )}
                        </ButtonBase>
                    </PatientCell>
                    <PatientCell width="120px" align="left">
                        <ButtonBase
                            onClick={(event: React.MouseEvent<HTMLElement>) => onSort(event, "lastUploadedOnIso")}
                        >
                            Last Updated
                            {patientSort.field === "lastUploadedOnIso" ? (
                                patientSort.order === "desc" ? (
                                    <ArrowDropDown />
                                ) : (
                                    <ArrowDropUp />
                                )
                            ) : (
                                ""
                            )}
                        </ButtonBase>
                    </PatientCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {isLoading ? (
                    <tr>
                        <td>
                            <Box
                                sx={{
                                    mr: 16,
                                    position: "absolute",
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <CircularProgress size={32} />
                            </Box>
                        </td>
                    </tr>
                ) : patients.length > 0 ? (
                    patients.map((patientDTO) => {
                        let patientUniqueStates = [] as any[];

                        if (selectedTrial?.workflowActive) {
                            patientUniqueStates = getPatientUniqueStates(patientDTO);
                        }

                        return (
                            <PatientRow
                                key={patientDTO["uuid"]}
                                onClick={(event: any) => onPatientClick(event, patientDTO)}
                                hover
                                data-test-id={`patientRow:${patientDTO["patientId"]}`}
                                selected={patientDTO.uuid === currentPatient?.uuid}
                                sx={
                                    patientDTO["uuid"] === currentPatient?.uuid
                                        ? {
                                              bgcolor: theme.palette.background.paper,
                                          }
                                        : {}
                                }
                            >
                                {hasPermissions(["QC"]) ? (
                                    <PatientCell>{patientDTO["isQc"] ? "QC" : ""}</PatientCell>
                                ) : null}
                                {selectedTrial?.workflowActive && (
                                    <PatientCell>
                                        <Box>
                                            <AnimatedChips elements={patientUniqueStates} />
                                        </Box>
                                    </PatientCell>
                                )}
                                <PatientCell>{patientDTO["patientId"]}</PatientCell>
                                {!currentPatient && (
                                    <PatientCell>
                                        {getCommaSeparatedPatientName(patientDTO["firstName"], patientDTO["lastName"])}
                                    </PatientCell>
                                )}
                                {selectedTrial?.showTags && (
                                    <PatientCell>
                                        <Box>
                                            <AnimatedChips keyString="uuid" elements={patientDTO.tags} />
                                        </Box>
                                    </PatientCell>
                                )}
                                <PatientCell>{patientDTO["site"]}</PatientCell>
                                {!currentPatient && <PatientCell>{patientDTO["gender"]}</PatientCell>}
                                {!currentPatient && (
                                    <PatientCell>{shortPrettyDate(patientDTO["dateOfBirth"])}</PatientCell>
                                )}
                                {!currentPatient && <PatientCell>{patientDTO["visits"]}</PatientCell>}
                                <PatientCell>{getPatientVisitDate(patientDTO)}</PatientCell>
                                <PatientCell>{shortPrettyDate(patientDTO["lastUploadedOnIso"])}</PatientCell>
                            </PatientRow>
                        );
                    })
                ) : null}
            </TableBody>
        </Table>
    );
};

export default React.memo(PatientTable);
