import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Chip,
    Box,
    CircularProgress,
    ListItemIcon,
    ListItemButton,
    Divider,
} from "@mui/material";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { useAppSelector } from "../../app/hooks";
import { useGetFormResponsesByMediaAndFormQuery, useGetFormTemplatesByTrialIdQuery } from "./dataAccess";
import DisplayText from "../../components/DisplayText/DisplayText";
import { getSelectedTrial } from "../auth/login/loginSlice";
import { FormTemplateDefinition } from "./nebula.port";
import DescriptionIcon from "@mui/icons-material/Description";
import { useGetUserDataQuery } from "../auth/dataAccess";
import TaskIcon from "@mui/icons-material/Task";
interface FormSelectorModalProps {
    open: boolean;
    mediaId: string;
    onClose: () => void;
    onSelectForm: (formId: string, isSubmitted: boolean) => void;
}

const FormSelectorModal: React.FC<FormSelectorModalProps> = ({ open, onClose, onSelectForm, mediaId }) => {
    const selectedTrial = useAppSelector(getSelectedTrial);
    const { data } = useGetUserDataQuery();

    const { data: formTemplates } = useGetFormTemplatesByTrialIdQuery(selectedTrial!.uuid);
    const { data: submittedResponses } = useGetFormResponsesByMediaAndFormQuery(mediaId);

    const handleListItemClick = (formTemplate: FormTemplateDefinition, hasFormBeenSubmittedByUser: boolean) => {
        if (!submittedResponses) return;

        onSelectForm(formTemplate.uuid, hasFormBeenSubmittedByUser);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Select a Form</DialogTitle>
            <Divider />
            <Box>
                <DialogContent>
                    {!formTemplates || !submittedResponses ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    ) : formTemplates.length === 0 ? (
                        <DisplayText text="No forms available" type="bodyMedium" />
                    ) : (
                        <>
                            <List disablePadding>
                                {formTemplates.map((form: FormTemplateDefinition) => {
                                    const hasFormBeenSubmittedByUser = submittedResponses.some(
                                        (response) =>
                                            response.formId === form.uuid && response.submittedBy.uuid === data!.userID
                                    );

                                    return (
                                        <ListItem
                                            disablePadding
                                            onClick={() => handleListItemClick(form, hasFormBeenSubmittedByUser)}
                                            key={form.uuid}
                                        >
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {hasFormBeenSubmittedByUser ? (
                                                        <TaskIcon color="success" />
                                                    ) : (
                                                        <DescriptionIcon />
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            {form.name}
                                                        </Box>
                                                    }
                                                />

                                                {hasFormBeenSubmittedByUser && (
                                                    <Chip
                                                        icon={<CheckCircleIcon style={{ fontSize: "0.75rem" }} />}
                                                        label="Submitted"
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                            marginLeft: "20",
                                                            height: "20px",
                                                            fontSize: "0.625rem",
                                                        }}
                                                    />
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </>
                    )}
                </DialogContent>
            </Box>
            <Divider />
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormSelectorModal;
