import { serviceSlice } from "../../app/serviceSlice";
import { mediaService } from "./nebula.port";
import { IUser } from "../auth/login/loginSlice";
import { IAnalysis, IApiResult, IEnFaceInfo, IMediaInfo } from "./viewerSlice";
import { RootState } from "../../app/store";

const extendedApiSlice = serviceSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEnFaceInfo: builder.query<IEnFaceInfo, string>({
            query: (mediaId) => () => mediaService.getEnFaceInfo(mediaId),
        }),
        getAnalysis: builder.query<IAnalysis[], string>({
            query: (studyId) => () => mediaService.getAnalysis(studyId),
            transformResponse(rawResult: IApiResult<IAnalysis[]>) {
                return rawResult.data;
            },
        }),
        getMediaInfo: builder.query<IMediaInfo, string>({
            query: (mediaId) => () => mediaService.getMediaInfo(mediaId),
        }),
        getSiteUsers: builder.query<IUser[], string>({
            query: (visitUuid) => () => mediaService.getSiteUsers(visitUuid),
        }),
        getModalities: builder.query<string[], string>({
            query: (trialId) => () => mediaService.getModalities(trialId),
        }),
        updateMediaModality: builder.mutation<void, { patientId: string; mediaId: string; modality: string }>({
            query:
                ({ patientId, mediaId, modality }) =>
                () =>
                    mediaService.updateMediaModality(patientId, mediaId, modality),
            async onQueryStarted({ patientId, mediaId, modality }, { dispatch, getState, queryFulfilled }) {
                const state = getState() as RootState;
                const showHidden = state.studies.showHidden;
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData(
                        // @ts-ignore
                        "getPatientStudiesByEncounter",
                        { patientId, showHidden },
                        (draft) => {
                            // @ts-ignore
                            draft.forEach((encounter: any, i: number) => {
                                encounter.studies.forEach((study: any) => {
                                    study.series.forEach((serie: any) => {
                                        serie.medias.forEach((media: any) => {
                                            if (media.uuid === mediaId) {
                                                media.dicomData.performedProcedureStepDescription = modality;
                                            }
                                        });
                                    });
                                });
                            });
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        bulkUpdateMediaModalities: builder.mutation<
            void,
            { patientId: string; mediaModalitiesUpdates: { mediaId: string; newModality: string }[] }
        >({
            query:
                ({ patientId, mediaModalitiesUpdates }) =>
                () =>
                    mediaService.bulkUpdateMediaModalities(patientId, mediaModalitiesUpdates),
            async onQueryStarted({ patientId, mediaModalitiesUpdates }, { dispatch, getState, queryFulfilled }) {
                const state = getState() as RootState;
                const showHidden = state.studies.showHidden;
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData(
                        // @ts-ignore
                        "getPatientStudiesByEncounter",
                        { patientId, showHidden },
                        (draft) => {
                            // @ts-ignore
                            draft.forEach((encounter: any, i: number) => {
                                encounter.studies.forEach((study: any) => {
                                    study.series.forEach((serie: any) => {
                                        serie.medias.forEach((media: any) => {
                                            mediaModalitiesUpdates.forEach((update) => {
                                                if (media.uuid === update.mediaId) {
                                                    media.dicomData.performedProcedureStepDescription =
                                                        update.newModality;
                                                }
                                            });
                                        });
                                    });
                                });
                            });
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        getTextFile: builder.query<string, string>({
            query: (textUrl) => () => mediaService.getTextFile(textUrl),
            transformResponse: async (rawResult: Blob) => {
                const text = await rawResult.text();
                return text;
            },
        }),
    }),
});

export const {
    useGetEnFaceInfoQuery,
    useGetAnalysisQuery,
    useGetMediaInfoQuery,
    useGetSiteUsersQuery,
    useGetTextFileQuery,
    useGetModalitiesQuery,
    useUpdateMediaModalityMutation,
    useBulkUpdateMediaModalitiesMutation,
} = extendedApiSlice;
