import React from "react";
import { Dialog, DialogTitle, DialogContent, CircularProgress, Divider } from "@mui/material";
import CustomForm from "./CustomForm";
import { useGetFormTemplateByMediaQuery, useSubmitFormResponseMutation } from "./dataAccess";
import { useConfirmationModal } from "../../components/ConfirmationDialog";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";

interface FormTemplateModalProps {
    open: boolean;
    onClose: () => void;
    formId: string;
    mediaId: string;
    mediaName: string;
}

const FormTemplateModal: React.FC<FormTemplateModalProps> = ({ open, onClose, mediaId, formId, mediaName }) => {
    const { openConfirmationModal: openCloseConfirmationModal } = useConfirmationModal({
        title: `Are you sure you want to cancel?`,
        content: "All data will be lost.",
        acceptLabel: "Yes",
    });
    const { enqueueSnackbar } = useSnackbar();
    const { data: mediaFormTemplate } = useGetFormTemplateByMediaQuery({ mediaId, formId });
    const [submitResponse] = useSubmitFormResponseMutation();

    const handleClose = () => {
        openCloseConfirmationModal(() => onClose());
    };

    const handleSubmit = async (values: Record<string, any>) => {
        try {
            await submitResponse({ mediaId, formId, responses: values }).unwrap();
            onClose();
            enqueueSnackbar("Form submitted successfully.", { variant: "success" });
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            {!mediaFormTemplate ? (
                <DialogContent>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 400 }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            ) : (
                <>
                    <DialogTitle>
                        <DisplayText variant="semiBold" text={`${mediaFormTemplate.name}`} type="bodyLarge" />
                        <DisplayText text={`${mediaName}`} type="bodySmall" />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <CustomForm formTemplate={mediaFormTemplate} onSubmit={handleSubmit} onCancel={onClose} />
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default FormTemplateModal;
