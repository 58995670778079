import { serviceSlice } from "../../app/serviceSlice";
import { customFormService, FormResponse, FormTemplateDefinition, MediaFormTemplate } from "./nebula.port";

export const extendedApiSlice = serviceSlice.enhanceEndpoints({ addTagTypes: ["FORM_RESPONSES"] }).injectEndpoints({
    endpoints: (builder) => ({
        getFormTemplatesByTrialId: builder.query<FormTemplateDefinition[], string>({
            query: (trialId) => () => customFormService.getFormTemplatesByTrialId(trialId),
        }),
        getFormTemplateByMedia: builder.query<MediaFormTemplate, { mediaId: string; formId: string }>({
            query:
                ({ mediaId, formId }) =>
                () =>
                    customFormService.getFormTemplateByMedia(mediaId, formId),
        }),
        getFormResponsesByMediaAndForm: builder.query<FormResponse[], string>({
            query: (mediaId) => () => customFormService.getFormResponsesByMediaAndForm(mediaId),
            providesTags: (result, error, mediaId) => [{ type: "FORM_RESPONSES", id: mediaId }],
        }),
        submitFormResponse: builder.mutation<
            FormResponse,
            { mediaId: string; formId: string; responses: Record<string, any> }
        >({
            query:
                ({ mediaId, formId, responses }) =>
                () =>
                    customFormService.submitFormResponse(mediaId, formId, responses),
            invalidatesTags: (result, error, { mediaId }) => [{ type: "FORM_RESPONSES", id: mediaId }],
        }),
        getFormReport: builder.query<string, { trialId: string; formId: string }>({
            query:
                ({ trialId, formId }) =>
                () =>
                    customFormService.getFormReport(trialId, formId),
            keepUnusedDataFor: 0,
        }),
    }),
});

export const selectFormResponseByUserandForm = (mediaId: string, formId: string, userId: string) => (state: any) => {
    const response = extendedApiSlice.endpoints.getFormResponsesByMediaAndForm.select(mediaId)(state);

    if (!response?.data) return null;

    const userResponse =
        response?.data.find((response) => response.formId === formId && response.submittedBy.uuid === userId) || null;

    return userResponse;
};

export const {
    useGetFormTemplatesByTrialIdQuery,
    useGetFormTemplateByMediaQuery,
    useGetFormResponsesByMediaAndFormQuery,
    useSubmitFormResponseMutation,
    useGetFormReportQuery,
} = extendedApiSlice;
