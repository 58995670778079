import { Box } from "@mui/material";
import { memo, useState, useEffect, useMemo, useCallback, useLayoutEffect, useRef } from "react";
import { TransformWrapper, TransformComponent, useControls, useTransformContext } from "react-zoom-pan-pinch";
import { IconButton } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

function useResizeObserver<T extends HTMLElement>() {
    const ref = useRef<T | null>(null);
    const [size, setSize] = useState({ width: 0, height: 0 });

    const updateSize = useCallback((entry: ResizeObserverEntry) => {
        const { contentRect } = entry;
        setSize({
            width: contentRect.width,
            height: contentRect.height,
        });
    }, []);

    useLayoutEffect(() => {
        if (!ref.current) return;

        const observer = new ResizeObserver(([entry]) => {
            updateSize(entry);
        });

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [updateSize]);

    return [ref, size] as const;
}

interface IImageMediaViewerProps {
    url: string;
    isFullscreen: boolean;
}

const ImageMediaViewer: React.FC<IImageMediaViewerProps> = ({ url, isFullscreen }) => {
    const [containerRef, { width: containerWidth, height: containerHeight }] = useResizeObserver<HTMLDivElement>();

    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    // const [containerWidth, setContainerWidth] = useState<number>(0);
    // const [containerHeight, setContainerHeight] = useState<number>(0);
    const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
    const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);
    const [isZoomed, setIsZoomed] = useState(false);

    const imageScale = useMemo(() => {
        if (containerWidth === 0 || containerHeight === 0 || imageNaturalWidth === 0 || imageNaturalHeight === 0)
            return 0;

        return Math.min(containerWidth / imageNaturalWidth, containerHeight / imageNaturalHeight);
    }, [containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight, isFullscreen]);

    useEffect(() => {
        const image = new Image();
        image.onload = () => {
            setImageNaturalWidth(image.naturalWidth);
            setImageNaturalHeight(image.naturalHeight);
        };
        image.src = url;
    }, [url]);

    return (
        <Box sx={{ height: "100%", width: "100%", position: "relative" }} ref={containerRef}>
            {imageScale > 0 && (
                <TransformWrapper
                    key={`${containerWidth}x${containerHeight}`}
                    initialScale={imageScale}
                    minScale={imageScale}
                    maxScale={imageScale * 100}
                    centerOnInit
                    onZoomStart={(e) => {}}
                    onZoom={(e) => {
                        setIsZoomed(true);
                    }}
                >
                    {({ resetTransform }) => (
                        <>
                            <IconButton
                                onClick={() => {
                                    resetTransform();
                                    setIsZoomed(false);
                                }}
                                sx={{
                                    "position": "absolute",
                                    "top": 8,
                                    "right": 8,
                                    "backgroundColor": "rgba(0, 0, 0, 0.5)",
                                    "color": "white",
                                    "padding": "4px 12px",
                                    "borderRadius": "20px",
                                    "fontSize": "0.875rem",
                                    "display": "flex",
                                    "gap": 1,
                                    "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    },
                                    "zIndex": 1,
                                }}
                            >
                                {isZoomed ? <RestartAltIcon fontSize="small" /> : <ZoomInIcon fontSize="small" />}
                                {isZoomed ? "Reset zoom" : "Scroll to zoom in"}
                            </IconButton>
                            <TransformComponent
                                wrapperStyle={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <img alt="study" src={url} />
                            </TransformComponent>
                        </>
                    )}
                </TransformWrapper>
            )}
        </Box>
    );
};

export default memo(ImageMediaViewer);
