import React, { useCallback, useState } from "react";
import { Box } from "@mui/system";
import { ArrowForwardIosSharp, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    CircularProgress,
    Typography,
    IconButton,
} from "@mui/material";
import CRFVisitTable from "../crf/CRFTable";
import { styled } from "@mui/styles";
import { useAppSelector } from "../../app/hooks";
import { IPatient, selectCurrentPatient } from "../patients/patientsSlice";
import { CrfField, CrfReading, CrfVisit } from "../crf/nebula.service";
import { getSelectedTrial } from "../auth/login/loginSlice";
import {
    useGetVisitPossibleTransitionsQuery,
    useGetVisitStateQuery,
    useTransitionVisitMutation,
} from "../workflow/dataAccess";
import WorkflowTransition from "./WorkflowTransitionDialog";
import WorkflowhistoryDialog from "./WorkflowHistoryDialog";
import WorkflowDropdown from "./WorkflowDropdown";
import ToolTip from "../../components/Tooltip/Tooltip";
import ListIcon from "@mui/icons-material/List";
import useRoles from "../permissions/useRoles";

const CustomAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={1} square {...props} />)(
    () => ({
        backgroundColor: "#1f2735",
    })
);

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />} {...props} />
))(() => ({
    "flexDirection": "row-reverse",
    "& .MuiAccordionSummary-content": {
        margin: "0px",
    },
}));

interface ICRFVisitAccordionProps {
    title: string;
    fields: CrfField[];
    CRFData: CrfReading[];
    visit: {
        name: string;
        uuid: string;
    };
}

const CRFVisitAccordion: React.FC<ICRFVisitAccordionProps> = ({ title, fields, CRFData, visit }) => {
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const currentPatient = useAppSelector(selectCurrentPatient);
    const selectedTrial = useAppSelector(getSelectedTrial);
    const { userHasRole } = useRoles();
    const [selectedTransition, setSelectedTransition] = useState("");
    const [siteWorkflowHistoryOpen, setSiteWorkflowHistoryOpen] = useState(false);

    const patient = currentPatient as IPatient;

    const { data: visitState } = useGetVisitStateQuery(
        { visitId: visit.uuid, patientId: currentPatient.uuid },
        {
            pollingInterval: 60_000,
            skip: selectedTrial?.workflowActive === false,
        }
    );

    const { data: visitPossibleTransitions } = useGetVisitPossibleTransitionsQuery(
        {
            visitId: visit.uuid,
            patientId: currentPatient.uuid,
        },
        {
            pollingInterval: 60_000,
            skip: selectedTrial?.workflowActive === false,
        }
    );

    const [transitionVisit] = useTransitionVisitMutation();

    const handleAccordionChange = (event: React.SyntheticEvent, expanded: boolean) => {
        setExpanded(expanded);
    };

    const handleOpenTransitionDialog = useCallback((event: any) => {
        event.stopPropagation();
        setSelectedTransition(event.target.value as string);
    }, []);

    const handleCloseTransitionDialog = () => {
        setSelectedTransition("");
    };

    const handleTransitionState = (transitionMessage = "") => {
        const transitionToMake = visitPossibleTransitions?.find(
            (transition) => transition.value === selectedTransition
        ) as any;
        return transitionVisit({
            patientId: currentPatient.uuid,
            visitId: visit.uuid,
            transition: transitionToMake,
            message: transitionMessage,
        }).unwrap();
    };

    const handleOpenWorkflowHistory = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setSiteWorkflowHistoryOpen(true);
    }, []);

    const handleCloseWorkflowHistory = () => {
        setSiteWorkflowHistoryOpen(false);
    };

    return (
        <Box sx={{ mt: 16 }}>
            <Box>
                {selectedTransition && (
                    <WorkflowTransition
                        onClose={handleCloseTransitionDialog}
                        transition={selectedTransition}
                        onTransition={handleTransitionState}
                        visitId={visit.uuid}
                    />
                )}
                {siteWorkflowHistoryOpen && (
                    <WorkflowhistoryDialog
                        visitName={visit.name}
                        onClose={handleCloseWorkflowHistory}
                        visitId={visit.uuid}
                        patientId={currentPatient.uuid}
                    />
                )}
                <CustomAccordion
                    TransitionProps={{ unmountOnExit: false }}
                    expanded={expanded}
                    onChange={handleAccordionChange}
                    sx={{ margin: "0px", backgroundColor: "#1f2735" }}
                >
                    <CustomAccordionSummary
                        sx={{
                            backgroundColor: "#1f2735",
                            boxShadow: "none",
                            border: "1px solid #A6C5E229",
                            borderRadius: "4px",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                        expandIcon={<ExpandMore />}
                    >
                        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                            <Typography sx={{ padding: "10px", flex: 1 }}>{title}</Typography>

                            {selectedTrial?.workflowActive && (
                                <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                                    <WorkflowDropdown
                                        onChange={handleOpenTransitionDialog}
                                        visitState={visitState}
                                        visitPossibleTransitions={visitPossibleTransitions}
                                    />

                                    {!userHasRole("SPONSOR") && (
                                        <ToolTip content={`View workflow history`}>
                                            <IconButton
                                                onClick={handleOpenWorkflowHistory}
                                                aria-label="history"
                                                size="small"
                                            >
                                                <ListIcon fontSize="inherit" />
                                            </IconButton>
                                        </ToolTip>
                                    )}
                                </Box>
                            )}

                            <Box
                                sx={{
                                    gap: "20px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    flex: 1,
                                }}
                            />
                        </Box>
                    </CustomAccordionSummary>
                    <AccordionDetails
                        sx={{
                            p: 16,
                            backgroundColor: "#1f2735",
                            boxShadow: "none",
                            border: "1px solid #A6C5E229",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderTop: 0,
                        }}
                    >
                        <CRFVisitTable patient={patient} fields={fields} data={CRFData} visit={visit} />
                    </AccordionDetails>
                </CustomAccordion>
            </Box>
        </Box>
    );
};

interface ICRFTabProps {
    visits?: CrfVisit[];
}

const CRFTab: React.FC<ICRFTabProps> = ({ visits }) => {
    if (!visits) {
        return (
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            {visits!.map((visit) => (
                <CRFVisitAccordion
                    fields={visit.fields}
                    key={visit.uuid}
                    CRFData={visit.data}
                    visit={{ name: visit.visitName, uuid: visit.uuid }}
                    title={`CRF for: ${visit.visitName}`}
                />
            ))}
        </>
    );
};

export default React.memo(CRFTab);
