import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Box,
    CircularProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Button,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useGetFormTemplatesByTrialIdQuery, useGetFormReportQuery } from "../customForm/dataAccess";
import { useAppSelector } from "../../app/hooks";
import { getSelectedTrial } from "../auth/login/loginSlice";
import { FormTemplateDefinition } from "../customForm/nebula.port";
import { useSnackbar } from "notistack";

interface ExportCustomFormModalProps {
    open: boolean;
    onClose: () => void;
}

const ExportCustomFormModal: React.FC<ExportCustomFormModalProps> = ({ open, onClose }) => {
    const selectedTrial = useAppSelector(getSelectedTrial);
    const { data: formTemplates, isLoading } = useGetFormTemplatesByTrialIdQuery(selectedTrial!.uuid);
    const [selectedFormTemplate, setSelectedFormTemplate] = useState<FormTemplateDefinition | null>(null);
    const [isExporting, setIsExporting] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let snackBar: any;

    const { data: csvReport } = useGetFormReportQuery(
        selectedFormTemplate
            ? { trialId: selectedFormTemplate.trialId, formId: selectedFormTemplate.uuid }
            : { trialId: "", formId: "" },
        { skip: !selectedFormTemplate }
    );

    useEffect(() => {
        if (csvReport && selectedFormTemplate) {
            const blob = new Blob([csvReport], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            const currentDate = new Date().toISOString().slice(0, 10);
            a.download = `${selectedFormTemplate.name}_form_${currentDate}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            enqueueSnackbar("Report exported successfully", { variant: "success" });
            setIsExporting(false);
            closeSnackbar(snackBar);
            onClose();
        }
    }, [csvReport, selectedFormTemplate, enqueueSnackbar, onClose]);

    const handleListItemClick = (formTemplate: FormTemplateDefinition) => {
        setSelectedFormTemplate(formTemplate);
        setIsExporting(true);
        snackBar = enqueueSnackbar("Running report...", { variant: "info" });
    };

    return (
        <Dialog open={open} maxWidth="md" onClose={onClose} disablePortal>
            <DialogTitle>Select the form you want to export</DialogTitle>
            <Divider />
            <DialogContent style={{ padding: 0 }}>
                {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 400 }}>
                        <CircularProgress />
                    </Box>
                ) : formTemplates && formTemplates.length > 0 ? (
                    <List sx={{ p: 0 }}>
                        {formTemplates.map((form) => (
                            <React.Fragment key={form.uuid}>
                                <ListItem disabled={isExporting} disablePadding sx={{ width: "100%", py: 4 }}>
                                    <ListItemButton
                                        disabled={isExporting}
                                        onClick={() => handleListItemClick(form)}
                                        sx={{ width: "100%" }}
                                    >
                                        <ListItemIcon sx={{ minWidth: 36 }}>
                                            <DescriptionIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={form.name} />
                                        <ListItemIcon>
                                            <GetAppIcon />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 400 }}>
                        No forms available for export
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportCustomFormModal;
