import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ListItemIcon } from "@mui/material";
import DisplayText from "../DisplayText/DisplayText";
import { nanoid } from "@reduxjs/toolkit";

const ITEM_HEIGHT = 48;

interface IOption {
    label: string;
    Icon: React.ReactNode;
    onClick?: () => void;
    labelStyle?: object;
    disabled?: boolean;
}

interface IMenuListProps {
    options: IOption[];
    disablePortal?: boolean;
}

const MenuList: React.FC<IMenuListProps> = ({ options, disablePortal = false }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                size="small"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * options.length,
                        width: "25ch",
                    },
                }}
                disablePortal={disablePortal}
            >
                {options.map(({ label, Icon, labelStyle, onClick = () => {}, disabled = false }) => {
                    const key = nanoid();

                    const handleClick = () => {
                        onClick();
                        handleClose();
                    };
                    return (
                        <MenuItem disabled={disabled} key={key} onClick={handleClick}>
                            <ListItemIcon>{Icon}</ListItemIcon>
                            <DisplayText type="bodyMedium" text={label} style={labelStyle} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default MenuList;
