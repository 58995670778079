import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import GridCellExpand from "../GridCellExpand";
import { useEditCRFMutation, useRemoveCrfFieldsMutation } from "../dataAccess";
import { useSnackbar } from "notistack";
import { CrfField } from "../nebula.service";
import React from "react";

interface IEditableGridCellProps extends IGridCellProps {
    laterality: string;
    visitId: string;
    patientId: string;
    email: string;
}

export const EditableGridCell: React.FC<IEditableGridCellProps> = ({
    fieldDefinition,
    laterality,
    visitId,
    patientId,
    email,
    ...params
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const apiRef = useGridApiContext();
    const [removeCrfFields] = useRemoveCrfFieldsMutation();
    const [editCRF] = useEditCRFMutation();

    const handleRemove = () => {
        try {
            const rowValues = apiRef.current.getRow(params.id);

            if (params.field === "notes") {
                const payload = { laterality: laterality, visitId: visitId, email: rowValues.userEmail, notes: "" };
                // @ts-ignore
                return editCRF({ patientId: patientId, crfUpdate: payload }).unwrap();
            }

            if (!fieldDefinition) return;

            return removeCrfFields({
                visitId,
                patientId,
                laterality,
                email,
                fieldsToRemove: [fieldDefinition.uuid],
            }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };
    return (
        <GridCellExpand
            isEditable={Boolean(params.isEditable)}
            formattedValue={params.formattedValue as string}
            value={params.value || ""}
            onRemove={handleRemove}
            width={params.colDef.computedWidth}
        />
    );
};

interface IGridCellProps extends GridRenderCellParams<string> {
    fieldDefinition?: CrfField;
}

export const GridCell: React.FC<IGridCellProps> = ({ fieldDefinition, ...params }) => {
    return (
        <GridCellExpand
            isEditable={false}
            formattedValue={params.formattedValue as string}
            value={params.value || ""}
            onRemove={() => {}}
            width={params.colDef.computedWidth}
        />
    );
};
