import React, { useState } from "react";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Box, Input, TextField } from "@mui/material";
import { moveToNextCell } from "../utils/moveToNextCell";

interface IDatePickerEditCellProps extends GridRenderEditCellParams {
    email: string;
    visitId: string;
    laterality: string;
    patientId: string;
    handleUpdate: (newValue: any) => Promise<any>;
}

export const DatePickerEditCell: React.FC<IDatePickerEditCellProps> = ({ id, value, field, handleUpdate }) => {
    const apiRef = useGridApiContext();
    const { enqueueSnackbar } = useSnackbar();
    const [dateValue, setDateValue] = useState<Date | null>(value ? new Date(value) : new Date());

    const handleUpdateValueOnServer = async (newValue: Date | null) => {
        if (!newValue) return;

        try {
            const dateString = new Date(newValue.setHours(0, 0, 0, 0)).toISOString(); // Format in ISO 8601 format with time set to midnight
            const isValid = await apiRef.current.setEditCellValue({ id, field, value: dateString });

            if (!isValid) return false;

            await apiRef.current.stopCellEditMode({ id, field });
            await handleUpdate(dateString);
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
            await apiRef.current.startCellEditMode({ id, field });
        }
    };

    const handleKeyDown = async (event: React.KeyboardEvent) => {
        if (event.key === "Enter" || event.key === "Tab") {
            event.preventDefault();
            event.stopPropagation();

            if (dateValue) {
                await handleUpdateValueOnServer(dateValue);
                setTimeout(() => moveToNextCell({ id, field }, apiRef, event), 0);
            }
        }
    };

    return (
        <Box sx={{ position: "absolute", left: 0, right: 0, margin: 1 }}>
            <DesktopDatePicker
                value={dateValue}
                onChange={(newValue: Date | null) => {
                    setDateValue(newValue);
                    if (newValue) {
                        handleUpdateValueOnServer(newValue);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        size="small"
                        autoFocus
                        onKeyDown={handleKeyDown}
                        sx={{
                            "backgroundColor": "rgb(32, 39, 53)",
                            "& .MuiIconButton-root": {
                                marginRight: "0",
                            },
                            "& .MuiInput-underline:before": {
                                borderBottom: "none",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottom: "none",
                            },
                            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                borderBottom: "none",
                            },
                            "& .MuiInputBase-input": {
                                marginLeft: "8px",
                                padding: 0,
                            },
                        }}
                        inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                        }}
                    />
                )}
            />
        </Box>
    );
};
