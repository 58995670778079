import React, { useMemo, useState } from "react";
import { TextField, Checkbox, Button, FormControlLabel, Box } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DisplayText from "../../components/DisplayText/DisplayText";
import { useConfirmationModal } from "../../components/ConfirmationDialog";

export type GridColumn = {
    uuid: string;
    label: string;
    userEditable: boolean;
    defaultValue?: string | boolean;
    type: "text" | "boolean";
};

export type FieldType = "text" | "boolean" | "grid";

export type FieldBase = {
    uuid: string;
    type: FieldType;
    label: string;
    required: boolean;
    userEditable: boolean;
    defaultValue?: string | boolean;
};

export type GridField = FieldBase & {
    type: "grid";
    columns: GridColumn[];
    rows: Record<string, any>[];
};

export type TextField = FieldBase & {
    type: "text";
};

export type BooleanField = FieldBase & {
    type: "boolean";
};

export type MediaFormField = TextField | BooleanField | GridField;

export type MediaFormTemplate = {
    uuid: string;
    trialId: string;
    fields: MediaFormField[];
    createdBy: string;
    createdAt: Date;
};

type CustomFormProps = {
    formTemplate: MediaFormTemplate;
    onSubmit?: (formData: Record<string, any>) => Promise<void>;
    onCancel: () => void;
    initialValues?: Record<string, any>;
    viewOnly?: boolean;
};

const CustomForm: React.FC<CustomFormProps> = ({
    formTemplate,
    onSubmit = async () => {},
    onCancel,
    initialValues = {},
    viewOnly = false,
}) => {
    const { openConfirmationModal: openCloseConfirmationModal } = useConfirmationModal({
        title: `Are you sure you want to cancel?`,
        content: "All data will be lost.",
        acceptLabel: "Yes",
    });

    const { openConfirmationModal: openSubmitConfirmationModal } = useConfirmationModal({
        title: `Are you sure you want to submit?`,
        content: "All data submitted won't be editable.",
        acceptLabel: "Yes, submit.",
    });

    const initialFormData = formTemplate.fields.reduce((acc, field) => {
        if (field.type === "text" || field.type === "boolean") {
            acc[field.uuid] =
                initialValues[field.uuid] !== undefined
                    ? initialValues[field.uuid]
                    : field.defaultValue !== undefined
                    ? field.defaultValue
                    : "";
        } else if (field.type === "grid") {
            acc[field.uuid] = initialValues[field.uuid] || field.rows || [[]];
        }
        return acc;
    }, {} as Record<string, any>);

    const [formData, setFormData] = useState<Record<string, any>>(initialFormData);
    const [formSubmitting, setFormSubmitting] = useState(false);

    const isFormValid = useMemo(() => {
        const isNotEmpty = (fieldValue: any) => fieldValue !== undefined && fieldValue !== "";

        const isValid = formTemplate.fields.every((field) => {
            if (field.required) {
                if (field.type === "grid") {
                    return formData[field.uuid]?.every((row: any) =>
                        field.columns.every((col) => isNotEmpty(row[col.uuid]))
                    );
                }
                return isNotEmpty(formData[field.uuid]);
            }
            return true;
        });

        return isValid;
    }, [formData]);

    const handleFieldChange = (fieldId: string, value: any) => {
        setFormData({
            ...formData,
            [fieldId]: value,
        });
    };

    const handleGridChange = (gridId: string, rowIndex: number, columnId: string, value: any) => {
        setFormData((prevFormData) => {
            const gridData = prevFormData[gridId] || [];
            const updatedRow = { ...(gridData[rowIndex] || {}), [columnId]: value };
            const updatedGridData = [...gridData];
            updatedGridData[rowIndex] = updatedRow;

            return {
                ...prevFormData,
                [gridId]: updatedGridData,
            };
        });
    };

    const renderTextField = (field: MediaFormField) => {
        if (field.type === "text") {
            return (
                <TextField
                    fullWidth
                    key={field.uuid}
                    label={field.label}
                    value={formData[field.uuid] ?? field.defaultValue ?? ""}
                    onChange={(e) => handleFieldChange(field.uuid, e.target.value)}
                    required={field.required}
                    disabled={!field.userEditable || viewOnly}
                    margin="normal"
                />
            );
        }
        return null;
    };

    const renderBooleanField = (field: MediaFormField) => {
        if (field.type === "boolean") {
            return (
                <FormControlLabel
                    key={field.uuid}
                    control={
                        <Checkbox
                            checked={Boolean(formData[field.uuid] ?? field.defaultValue ?? false)}
                            onChange={(e) => handleFieldChange(field.uuid, e.target.checked)}
                            disabled={!field.userEditable || viewOnly}
                        />
                    }
                    label={field.label}
                />
            );
        }
        return null;
    };

    const renderGridField = (field: GridField) => {
        if (field.type === "grid") {
            const columns: GridColDef[] = field.columns.map((col) => ({
                field: col.uuid,
                headerName: col.label,
                width: 150,
                editable: col.userEditable && !viewOnly,
                sortable: false,
                type: col.type === "boolean" ? "boolean" : "string",
                renderCell: (params: GridRenderCellParams) => {
                    if (col.type === "boolean") {
                        return <Checkbox checked={Boolean(params.value)} disabled={!col.userEditable || viewOnly} />;
                    }
                    return params.value;
                },
            }));

            const rows =
                formData[field.uuid]?.map((row: any, index: any) => ({
                    id: index,
                    ...row,
                })) || [];

            return (
                <Box key={field.uuid} sx={{ height: 400, width: "100%", marginTop: 2 }}>
                    <DisplayText text={field.label} type="bodyLarge" style={{ marginBottom: "6px" }} />
                    <DataGrid
                        autoHeight
                        sx={{
                            "& .MuiDataGrid-row:nth-of-type(odd)": {
                                backgroundColor: "#3e454f",
                            },
                            "& .MuiDataGrid-row:nth-of-type(even)": {
                                backgroundColor: "#484f5b",
                            },
                            "& .MuiDataGrid-root": {
                                border: "1px solid #efeded",
                            },
                            "& .MuiDataGrid-main": {
                                border: "1px solid #efeded",
                            },
                            "& .MuiDataGrid-cell": {
                                borderColor: "#efeded",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#484f5b",
                            },
                        }}
                        rows={rows}
                        columns={columns}
                        pageSize={rows.length}
                        onCellEditCommit={(params) =>
                            handleGridChange(field.uuid, params.id as number, params.field, params.value)
                        }
                        density="compact"
                        disableColumnFilter
                        disableColumnMenu
                        disableDensitySelector
                        disableSelectionOnClick
                        hideFooter
                    />
                </Box>
            );
        }
        return null;
    };

    const handleCancel = () => {
        openCloseConfirmationModal(() => onCancel());
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        openSubmitConfirmationModal(async () => {
            setFormSubmitting(true);
            await onSubmit(formData);
            setFormSubmitting(false);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ overflow: "hidden" }}>
                <Box sx={{ overflow: "auto", display: "flex", flexDirection: "column", gap: 8, p: 12 }}>
                    {formTemplate.fields.map((field) => (
                        <Box key={field.uuid}>
                            {field.type === "text" && renderTextField(field)}
                            {field.type === "boolean" && renderBooleanField(field)}
                            {field.type === "grid" && renderGridField(field as GridField)}
                        </Box>
                    ))}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 16 }}>
                    {viewOnly ? (
                        <Button variant="contained" onClick={onCancel}>
                            Close
                        </Button>
                    ) : (
                        <>
                            <Button variant="outlined" onClick={handleCancel} sx={{ mr: 6 }}>
                                Cancel
                            </Button>
                            <Button disabled={!isFormValid || formSubmitting} variant="contained" type="submit">
                                {formSubmitting ? "Submitting..." : "Submit"}
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </form>
    );
};

export default CustomForm;
