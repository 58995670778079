import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logoutUser, selectUserInfo } from "../auth/login/loginSlice";
import { useGetUserDataQuery } from "../auth/dataAccess";
import Menu from "../menu/Menu";
import DisplayText from "../../components/DisplayText/DisplayText";
import LogoImg from "../../assets/SquareLogo.png";
import ComingSoonFeature from "../../components/ComingSoonFeature/ComingSoonFeature";
import { Drawer, AppBar, Toolbar, IconButton, Badge, Menu as AccountMenu, MenuItem, Button } from "@mui/material";
import { AccountCircle, Menu as MenuIcon, Notifications } from "@mui/icons-material";
import { Box, styled } from "@mui/system";
import theme from "../../app/theme";
import TrialDropdown from "./TrialDropdown";
import ExportCustomFormModal from "../menu/ExportFormModal";

const Image = styled("img")(({ theme }) => ({
    maxHeight: theme.spacing(40),
    display: "none",
    [theme.breakpoints.up("sm")]: {
        display: "block",
    },
}));

const NavBar = () => {
    const userInfo = useAppSelector(selectUserInfo);

    const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);
    const isProfileMenuOpen = Boolean(profileAnchorEl);
    const dispatch = useAppDispatch();

    const [menuOpen, setOpen] = useState(false);
    const [exportModalOpen, setExportModalOpen] = React.useState(false);

    const handleExportClick = () => {
        setExportModalOpen(true);
    };

    const handleClose = () => {
        setExportModalOpen(false);
    };

    const { data: userData } = useGetUserDataQuery();

    const handleLogOut = () => {
        dispatch(logoutUser());
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setOpen(open);
    };

    const menuId = "primary-search-account-menu";

    const renderProfileMenu = (
        <AccountMenu
            anchorEl={profileAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isProfileMenuOpen}
            onClose={handleProfileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
        </AccountMenu>
    );

    return (
        <Box sx={{ flex: "0 0 65px" }}>
            <AppBar sx={{ bgcolor: theme.palette.background.default, p: "0px 4px" }} position="static">
                <Toolbar>
                    {userInfo.isLoggedIn ? (
                        <>
                            <IconButton onClick={toggleDrawer(true)} aria-label="open menu">
                                <MenuIcon />
                            </IconButton>
                            <Drawer open={menuOpen} onClose={toggleDrawer(false)}>
                                <Menu onExportClick={handleExportClick} onClose={toggleDrawer(false)} />
                            </Drawer>
                        </>
                    ) : null}
                    <Link to="/">
                        <Image src={LogoImg} alt="VoxeleronLogo" />
                    </Link>
                    {userInfo.isLoggedIn ? (
                        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, ml: 10 }}>
                            <Box>
                                <DisplayText
                                    type="h5"
                                    variant="regular"
                                    text="iNebula"
                                    style={{ marginBottom: "0px" }}
                                />
                                <DisplayText
                                    type="bodyXSmall"
                                    variant="regular"
                                    text="by Voxeleron"
                                    style={{ marginBottom: "0px" }}
                                />
                            </Box>
                            <Box sx={{ alignSelf: "flex-end" }}>
                                <TrialDropdown />
                            </Box>
                            <Box
                                sx={{
                                    display: "none",
                                    [theme.breakpoints.up("md")]: {
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "none",
                                        [theme.breakpoints.up("sm")]: {
                                            display: "flex",
                                            gap: "8px",
                                        },
                                    }}
                                >
                                    <ComingSoonFeature>
                                        <IconButton aria-label="Notifications">
                                            <Badge badgeContent={5} color="secondary">
                                                <Notifications />
                                            </Badge>
                                        </IconButton>
                                    </ComingSoonFeature>
                                    <Button
                                        aria-label="My Account"
                                        aria-controls={menuId}
                                        size="large"
                                        aria-haspopup="true"
                                        startIcon={<AccountCircle />}
                                        onClick={handleProfileMenuOpen}
                                    >
                                        <DisplayText
                                            style={{ color: "white" }}
                                            text={userData?.email || ""}
                                            type="bodyMedium"
                                            variant="regular"
                                        />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    ) : null}
                </Toolbar>
            </AppBar>
            {renderProfileMenu}
            {exportModalOpen && <ExportCustomFormModal open={exportModalOpen} onClose={handleClose} />}
        </Box>
    );
};
export default NavBar;
