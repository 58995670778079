import { nebulaClient2 } from "../../lib/nebulaClient";

export type GridColumnDefinition = {
    uuid: string;
    label: string;
    defaultValue?: string | boolean; // $media and $user can be used to auto-populate
    userEditable: boolean;
    type: "text" | "boolean";
};

export enum FieldTypeEnum {
    TEXT = "text",
    BOOLEAN = "boolean",
    GRID = "grid",
}

export type FieldBaseDefinition = {
    uuid: string;
    type: FieldType;
    label: string;
    required: boolean;
    userEditable: boolean;
};

export type GridFieldDefinition = FieldBaseDefinition & {
    type: "grid";
    gridConfig: GridColumnDefinition[];
    rows: number;
};

export type TextFieldDefinition = FieldBaseDefinition & {
    defaultValue?: string;
    type: "text";
};

export type BooleanFieldDefinition = FieldBaseDefinition & {
    defaultValue?: boolean;
    type: "boolean";
};

export type FieldDefinition = TextFieldDefinition | BooleanFieldDefinition | GridFieldDefinition;

export type FormTemplateDefinition = {
    uuid: string;
    name: string;
    trialId: string;
    fields: FieldDefinition[];
    createdBy: string;
    createdAt: Date;
};

// This type is used when the form is returned to the client, with evaluated default values and populated rows for grids
export type GridColumn = {
    uuid: string;
    label: string;
    userEditable: boolean;
    defaultValue?: string | boolean; // Evaluated default value, no placeholders
    type: "text" | "boolean";
};

export type FieldType = "text" | "boolean" | "grid";

export type FieldBase = {
    uuid: string;
    type: FieldType;
    label: string;
    required: boolean;
    userEditable: boolean;
    defaultValue?: string | boolean; // Evaluated default value, no placeholders
};

export type GridField = FieldBase & {
    type: "grid";
    columns: GridColumn[]; // Evaluated columns for the grid
    rows: Record<string, any>[]; // Rows with evaluated default values
};

export type TextField = FieldBase & {
    type: "text";
};

export type BooleanField = FieldBase & {
    type: "boolean";
};

export type MediaFormField = TextField | BooleanField | GridField;

export type MediaFormTemplate = {
    uuid: string;
    name: string;
    trialId: string;
    fields: MediaFormField[];
    createdBy: string;
    createdAt: Date;
};

export type FormResponse = {
    uuid: string;
    formId: string;
    trialId: string;
    mediaId: string;
    patient: { uuid: string; patientId: string };
    responses: Record<string, any>;
    submittedBy: { uuid: string; email: string };
    submittedAt: Date;
};

export const customFormService = {
    getFormTemplatesByTrialId: async (trialId: string) => {
        const response = await nebulaClient2.get<FormTemplateDefinition[]>(`/trial/${trialId}/form`);

        return response;
    },
    getFormTemplateByMedia: async (mediaId: string, formId: string) => {
        const response = await nebulaClient2.get<MediaFormTemplate>(`/media/${mediaId}/form/${formId}`);

        return response;
    },
    getFormResponsesByMediaAndForm: async (mediaId: string) => {
        const response = await nebulaClient2.get<FormResponse[]>(`/media/${mediaId}/response`);

        return response;
    },
    submitFormResponse: async (mediaId: string, formId: string, responses: Record<string, any>) => {
        const response = await nebulaClient2.post<FormResponse>(`media/${mediaId}/form/${formId}/submit`, {
            responses,
        });

        return response;
    },
    getFormReport: async (trialId: string, formId: string) => {
        const response = await nebulaClient2.get<string>(`/trial/${trialId}/form/${formId}/report`);

        return response;
    },
};
