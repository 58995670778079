import React from "react";
import { Dialog, DialogTitle, DialogContent, CircularProgress, Divider, Button } from "@mui/material";
import CustomForm from "./CustomForm";
import { useGetFormTemplateByMediaQuery, useGetFormResponsesByMediaAndFormQuery } from "./dataAccess";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import { useAppSelector } from "../../app/hooks";
import { selectFormResponseByUserandForm } from "./dataAccess";
import { useGetUserDataQuery } from "../auth/dataAccess";

interface FormViewOnlyModalProps {
    open: boolean;
    onClose: () => void;
    formId: string;
    mediaId: string;
    mediaName: string;
}

const FormViewOnlyModal: React.FC<FormViewOnlyModalProps> = ({ open, onClose, mediaId, formId, mediaName }) => {
    const { data: mediaFormTemplate } = useGetFormTemplateByMediaQuery({ mediaId, formId });
    const { data: userData } = useGetUserDataQuery();
    const reponseValues = useAppSelector(selectFormResponseByUserandForm(mediaId, formId, userData!.userID));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            {!mediaFormTemplate ? (
                <DialogContent>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 400 }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            ) : (
                <>
                    <DialogTitle>
                        <DisplayText variant="semiBold" text={`${mediaFormTemplate.name}`} type="bodyLarge" />
                        <DisplayText text={`${mediaName}`} type="bodySmall" />
                        <DisplayText
                            text={`Submitted by: ${userData?.email} on ${new Date(
                                reponseValues?.submittedAt || new Date()
                            ).toLocaleString()}`}
                            type="bodyXSmall"
                        />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <CustomForm
                            formTemplate={mediaFormTemplate}
                            onCancel={onClose}
                            initialValues={reponseValues?.responses || {}}
                            viewOnly={true}
                        />
                    </DialogContent>
                    <Divider />
                </>
            )}
        </Dialog>
    );
};

export default FormViewOnlyModal;
